import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: white;
`;

const Overlay = styled.div`
  height: 100vh;
  width: 900px;
  z-index: 2;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  display: ${(props) => !props.hasClicked ? 'flex' : 'none'};
`;

const Scrmr = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${(props) => props.hasClicked ? 'flex' : 'none'};
  z-index: 1;
  & > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const Logo = styled.img`
  width: 300px;
  height: auto;
`;

const Button = styled.button`
  font-size: 1.2em;
  background-color: #101010;
  border: none;
  padding: 0 20px;
  color: white;
  cursor: pointer;
`;

const InputText = styled.input`
  height: 30px;
  font-size: 1.2em;
  width: 400px;
  outline: none;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  background-color: #151515;
`;

const HeaderButton = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  :hover {
    background-color: #303030;
  }
`;

const RegionButton = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${(props) => props.active ? '#050505' : 'transparent'};
  :hover {
    background-color: #050505;
  }
  margin-right: 5px;
`;

const RegionSelector = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  padding: 20px 0px;
  width: 100%;
`;

const PlayerList = styled.table`
  width: 100%;
  & > tbody > tr > td {
    text-align: center;
    padding: 10px 0;
  }
`;

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const rnd = randomNumber(1, 5);

const players = [
  { player: 'Send0o', division: 'Challenger I', points: 1034, server: 'EUW' },
  { player: 'FCB Xixauxas', division: 'Grandmaster I', points: 845, server: 'EUW' },
  { player: 'Ffaka', division: 'Master I', points: 22, server: 'EUW' },
  { player: 'Elmiillor', division: 'Master I', points: 0, server: 'EUW' },
  { player: 'AdcShiiro', division: 'Diamond II', points: 0, server: 'EUW' },
];

function App() {
  const [hasClicked, setClicked] = useState(false);
  const [region, setRegion] = useState('EUW');
  const [summoner, setSummoner] = useState('');
  const videoRef = useRef(null);

  function trigger() {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return ev.returnValue = 'LKSADJHFLKASJDHFLKJASHDLKFJHASLDKJFH';
    });

    videoRef.current.play();
    setClicked(true);
    const { documentElement } = document;
    if (documentElement.requestFullscreen) documentElement.requestFullscreen();
    else if (documentElement.mozRequestFullScreen) documentElement.mozRequestFullScreen();
    else if (documentElement.webkitRequestFullscreen) documentElement.webkitRequestFullscreen();
    else if (documentElement.msRequestFullscreen) documentElement.msRequestFullscreen();
  }

  function onKeyPress(event) {
    if(event.key === 'Enter'){
      trigger();
    }
  }

  return (
    <Container>
      <Overlay hasClicked={hasClicked}>
        <Logo src={`${process.env.PUBLIC_URL}/logo.png`} />
        <Header>
          <HeaderButton onClick={trigger}>Home</HeaderButton>
          <HeaderButton onClick={trigger}>Leaderboard</HeaderButton>
          <HeaderButton onClick={trigger}>About</HeaderButton>
          <HeaderButton onClick={trigger}>Privacy</HeaderButton>
        </Header>
        <Content>
          <FormContainer>
            <RegionSelector>
              <RegionButton active={region === 'EUW'} onClick={() => setRegion('EUW')}>EUW</RegionButton>
              <RegionButton active={region === 'EUNE'} onClick={() => setRegion('EUNE')}>EUNE</RegionButton>
              <RegionButton active={region === 'NA'} onClick={() => setRegion('NA')}>NA</RegionButton>
              <RegionButton active={region === 'RU'} onClick={() => setRegion('RU')}>RU</RegionButton>
              <RegionButton active={region === 'TR'} onClick={() => setRegion('TR')}>TR</RegionButton>
              <RegionButton active={region === 'JP'} onClick={() => setRegion('JP')}>JP</RegionButton>
              <RegionButton active={region === 'LAN'} onClick={() => setRegion('LAN')}>LAN</RegionButton>
              <RegionButton active={region === 'LAS'} onClick={() => setRegion('LAS')}>LAS</RegionButton>
            </RegionSelector>
            <Form>
              <InputText placeholder='Summoner Name' autoFocus onKeyPress={onKeyPress} onChange={(event) => setSummoner(event.target.value)}></InputText>
              <Button onClick={trigger}>Search</Button>
            </Form>
            {
              region && summoner && (
                <a target='_blank' rel='noreferrer' href={`https://worlds.lol/${region.toLocaleLowerCase()}/${encodeURIComponent(summoner.toLocaleLowerCase())}`}>https://worlds.lol/{region.toLocaleLowerCase()}/{encodeURIComponent(summoner.toLocaleLowerCase())}</a>
              )
            }
          </FormContainer>
          <h2 style={{textAlign: 'center'}}>TOP accounts</h2>
          <PlayerList>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Player</th>
                <th>Division</th>
                <th>Points</th>
                <th>Server</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{player.player}</td>
                  <td>{player.division}</td>
                  <td>{player.points}</td>
                  <td>{player.server}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </PlayerList>
        </Content>
      </Overlay>
      <Scrmr hasClicked={hasClicked}>
        <video
          ref={videoRef}
          id="video"
          src={`https://d3jg3da0o1gfhh.cloudfront.net/scrmr/twitter-javilobo8-${rnd}.mp4`}
          loop>
        </video>
      </Scrmr>
    </Container>
  );
}

export default App;
